<!--<link href="/src/styles/css/quill.snow.css" rel="stylesheet">-->
<!--<script src="/src/scripts/quill.js"></script>-->
<!--<div class="section-image" >-->
<!--</div>-->
<!--<div class="container">-->

<!--</div>-->

<div class="container-fluid-login">

<!--  <div class="" style="margin-top:100px">-->
<!--    <div class="">-->
<!--  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4 pt-3">-->

<!--      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">-->

<!--        <div class="d-flex flex-row  align-items-center  justify-content-center ">-->
<!--          <div class="col-12 col-md-8 col-lg-6 col-xl-6">-->
          <div class="card shadow">
            <div class="card-header card-header-indigo mt-n3 mx-3 bg-indigo" >
              <h4 i18n class="card-title text-center text-white">Login</h4>
              <p i18n class="card-category text-center text-white">Stay updated on your fashion world</p>
            </div>
            <div class="card-body mx-3">
              <div class="text-center mt-2 mb-3">
                <button mat-button routerLink="/home" color="primary" class="fw-bold fs-4 text-primary-logo">
                  <span i18n>Monochrome</span>
                </button>
              </div>

              <form [formGroup]="loginForm" >

                <!--  <mat-card class="shipping-card">-->
                <!--    <mat-card-header>-->
                <!--      <mat-card-title>Login Information</mat-card-title>-->
                <!--    </mat-card-header>-->
                <!--    <mat-card-content>-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <h4 class=" text-center ">Sign in</h4>-->
<!--                    <div class=" text-center ">Stay updated on your fashion world</div>-->

<!--                  </div>-->
<!--                </div>-->
                <div class="row mt-4">
                  <div class="col">

<!--                    <span class="input-group-addon">-->
<!--                      <span class="material-symbols-outlined">face</span>-->
<!--                    </span>-->
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label >
                        <i class="me-1">
                          <mat-icon *ngIf="this.username.invalid" class="material-symbols-outlined">drafts</mat-icon>
                          <mat-icon *ngIf="this.username.valid" class="material-symbols-outlined">mail_outline</mat-icon>
                        </i>
                        <span i18n>Email</span>
                      </mat-label>
                      <input i18n-placeholder type="email" matInput placeholder="email" formControlName="username" required>
                      <mat-error *ngIf="username.invalid">{{getLoginErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
<!--                 <span class="input-group-addon">-->
<!--&lt;!&ndash;                    <i class="material-icons">lock_open</i>&ndash;&gt;-->
<!--                   <span class="material-symbols-outlined">lock</span>-->
<!--                 </span>-->
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>
                        <i class="me-1">
                          <mat-icon *ngIf="this.password.invalid" class="">lock_open</mat-icon>
                          <mat-icon *ngIf="this.password.valid" class="material-symbols-outlined">lock</mat-icon>
                        </i>
                        <span i18n>Password</span>
                      </mat-label>
                      <input i18n-placeholder matInput [type]="showPassword ? 'text' : 'password'"
                             placeholder="6 or more characters" formControlName="password"
                             (keyup.enter)="onSubmit()">
                      <button mat-icon-button matSuffix type="button"
                              (click)="showPassword = !showPassword"
                              [attr.aria-label]="'Hide password'"
                              [attr.aria-pressed]="showPassword">
                        <mat-icon class="material-symbols-outlined">
                          {{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                      </button>
                      <mat-error *ngIf="password.invalid">{{getLoginErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row ">
                  <div class=" text-center ">
                    <button i18n mat-button routerLink="/forgot-password" type="button" color="primary">
                      Forgot password?
                    </button>
                  </div>
                </div>

                <!--    </mat-card-content>-->
                <div class="row pt-3">
                  <div class="text-center">
                    <mat-card-actions>
                      <div class="fw-lighter pb-3">
                        <span i18n>By signing up, you agree to our </span>
                        <!--                      <a i18n role="button" (click)="readTermsAndConditions()" class="text-decoration-none">-->
                        <!--                        Terms of Service,-->
                        <!--                      </a>-->
                        <a role="button" target="_blank" href="footer/general-conditions" class="text-decoration-none">
                          <span i18n>General conditions</span>,
                        </a>
                        <a role="button" target="_blank" href="footer/privacy-policy" class="text-decoration-none">
                          <span i18n>Privacy Policy</span>,
                        </a>
                        <a role="button" target="_blank" href="footer/cookie-policy" class="text-decoration-none">
                          <span i18n>Cookie policy</span>
                        </a>
                      </div>
                      <button i18n mat-raised-button color="primary" class="w-50" type="button"
                              [disabled] = "loginForm.invalid"
                              (click)="onSubmit()">
<!--                        *ngIf="!authenticationService.authenticated">-->

                        Login
                      </button>
<!--                      <button class="btn btn-primary"  type="button"-->
<!--                              (click)="logout()"-->
<!--                              *ngIf="authenticationService.authenticated">Bootstrap Logout-->
<!--                      </button>-->
<!--                      <button mat-stroked-button color="primary"  type="button"-->
<!--                              (click)="logout()"-->
<!--                              *ngIf="authenticationService.authenticated">Angular Logout-->
<!--                      </button>-->
                    </mat-card-actions>
                  </div>
                </div>

<!--                </div>-->
                <!--  </mat-card>-->
<!--                <div class="row pt-4">-->
                <div class="fw-lighter pt-4 text-center"> <span i18n>New to Monochrome?</span></div>
                  <div class="pt-3 text-center">
                    <button i18n mat-button routerLink="/register" color="primary" type="button">
                       Join now
                    </button>
                  </div>
<!--                </div>-->



              </form>
              <!--{{this.loginForm.controls['username'].value}}-->

              <div class="row">
                <div class="text-center mt-2">
                  <app-language-select [buttonType]="'button'"></app-language-select>
                </div>
              </div>
            </div>
          </div>
<!--        </div>-->
<!--&lt;!&ndash;</section>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--</div>-->

<!--  <div class="item">I am centered!</div>-->

<!--  <div class="box">-->
<!--    <div>One</div>-->
<!--    <div>Two</div>-->
<!--    <div class="selected">Three</div>-->
<!--    <div>Four</div>-->
<!--  </div>-->

</div>
