import {Injectable} from '@angular/core';
import {DomainDto} from '../admin/admin-domain/DomainDto';
import {AppLanguageDto} from '../common/app-language-select/AppLanguageDto';
import {AppCookieDto} from '../common/model/AppCookieDto';

const DOMAIN = 'locationDomain';
const LANGUAGE = 'currentLanguage';
const SUPPORTED_LANGUAGES = 'supportedLanguages';
const USERNAME = 'username';
const USER_ROLE = 'userRole';
const USER_AUTHORITIES = 'userAuthorities';
const _object = '_object';
const _userCookieSettings = '_userCookieSettings';
// const SHIPPING_COMPANIES = 'shippingCompanies';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  constructor() {
  }


  getData(key: string): any {
    key = key + 'Object';
    const data = localStorage.getItem(key);
    if (data && data !== 'undefined') {
      return JSON.parse(localStorage.getItem(key));
    }
    return '';
  }

  setData(key: string, value: any): void {
    key = key + 'Object';
    localStorage.setItem(key, JSON.stringify(value));
  }


  getDataString(key: string): string {
    return localStorage.getItem(key);
  }

  setDataString(key: string, value: string): void {
    localStorage.setItem(key, value);
  }


  removeData(key: string): void {
    localStorage.removeItem(key);
  }

  clearData(): void {
    localStorage.clear();
  }

  getSessionData(key: string): any {
    key = key + _object;
    return JSON.parse(sessionStorage.getItem(key));
  }

  setSessionData(key: string, value: any): void {
    key = key + _object;
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  clearUserData(): void {
    this.removeData(DOMAIN);
    this.removeData(DOMAIN + 'Object');
    this.removeData(USERNAME);
    this.removeData(USERNAME + 'Object');
    this.removeData(USER_ROLE);
    this.removeData(USER_ROLE + 'Object');
    this.removeData(USER_AUTHORITIES);
    this.removeData(USER_AUTHORITIES + 'Object');
    this.removeData('token');

    console.log('7777777777777700000000', this.getData(USER_AUTHORITIES));
  }

  // addCookie(allowRequired: boolean, allowAnalytic: boolean,
  //   allowFunctional: boolean, allowAdvertise: boolean) {
  //   console.log('133355555555555',
  //     allowRequired, allowAnalytic, allowFunctional, allowAdvertise);
  addCookieAccepted() {
    this.setDataString('isCookiesAccepted', 'true');
  }

  checkCookie(): string {
    return this.getDataString('isCookiesAccepted');
  }

  clearCookie() {
    this.removeData('isCookiesAccepted');
  }


  getDomainString(): string {
    return this.getDataString(DOMAIN);
  }
  setDomainString(domain: string) {
    return this.setDataString(DOMAIN, domain);
  }
  getDomain() {
    return this.getData(DOMAIN);
  }
  setDomain(domain: string) {
    const domainObj = {} as DomainDto;
    domainObj.name = domain;
    return this.setData(DOMAIN, domainObj);
  }

  getLanguage(): string {
    return this.getDataString(LANGUAGE);
  }
  setLanguage(language: string) {
    return this.setDataString(LANGUAGE, language);
  }

  getSupportedLanguages(): AppLanguageDto[] {
    return this.getData(SUPPORTED_LANGUAGES);
  }
  setSupportedLanguages(supportedLanguages: AppLanguageDto[]) {
    return this.setData(SUPPORTED_LANGUAGES, supportedLanguages);
  }

  getUserName(): string {
    return this.getDataString(USERNAME);
  }
  setUserName(username: string) {
    return this.setDataString(USERNAME, username);
  }

  getUserRole(): string {
    const userAuthorities = this.getUserAuthorities();
    if (userAuthorities) {
      const role = userAuthorities.find(role => role.includes('ROLE_'));
      return role;
    }
    return '';
  }
  getUserAuthorities() {
    console.log('777777777777777777', this.getData(USER_AUTHORITIES));
    return this.getData(USER_AUTHORITIES);
  }

  setUserAuthorities(authorities: string[]) {
    console.log('777777777777777777', authorities);
    return this.setData(USER_AUTHORITIES, authorities);
  }

  getUserCookieSettings(): AppCookieDto {
    return this.getData(_userCookieSettings);
  }
  setUserCookieSettings(cookieDto: AppCookieDto) {
    cookieDto.allowRequired = true;
    this.setData(_userCookieSettings, cookieDto);

    // this.setDataString('allowRequired',
    //   cookieDto.allowRequired ? cookieDto.allowRequired.toString() : '');
    // this.setDataString('allowAnalytic',
    //   cookieDto.allowAnalytic ? cookieDto.allowAnalytic.toString() : '');
    // this.setDataString('allowFunctional',
    //   cookieDto.allowFunctional ? cookieDto.allowFunctional.toString() : '');
    // this.setDataString('allowAdvertise',
    //   cookieDto.allowAdvertise ? cookieDto.allowAdvertise.toString() : '');
  }

  // getSupportedShippingCompanies(): string[] {
  //   return this.getData(SHIPPING_COMPANIES);
  // }
  // setSupportedShippingCompanies(shippingCompanies) {
  //   this.setData(SHIPPING_COMPANIES, shippingCompanies);
  // }

}
