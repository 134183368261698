import { RoleType } from './RoleType';
import {FileInfo} from '../app-item/FileInfo';
import {AdminRoleDto} from '../admin/admin-user/admin-role/AdminRoleDto';

export class User {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  username: string;
  company: string;
  password: string;
  confirmPassword: string;
  email: string;
  token: string;
  phoneNumber: string;
  avatar: string;
  avatarFile: FileInfo;
  description: string;
  role: RoleType;
  roles?: AdminRoleDto[];
  isNewUSer: boolean;
}
