import {inject, Injectable, signal} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoleType } from '../user/RoleType';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import {environment} from '../../environments/environment';
import {AppMessageService} from '../message/app-message.service';
import {AppStorageService} from '../storageService/app-storage.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {BehaviorSubject, from, Observable} from 'rxjs';
import firebase from 'firebase/compat';
import UserCredential = firebase.auth.UserCredential;

const baseLoginUrl = environment.apiEndpoint + '/api/public/user/login';

const baseLogoutUrl = environment.apiEndpoint + '/api/admin/control/logout';

const userLogoutUrl = environment.apiEndpoint + '/api/protected/user/logout';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // !!this.getToken() === this.getToken() ? true: false
  authenticated = !!this.isAuthenticatedUser;
  redirectUrl = 'login';
  _user$ = this.afAuth.user;
  _currentUser = this.afAuth.currentUser;
  // currentUserSig = signal<string | null | undefined>(undefined);

  currentUserSubject = new BehaviorSubject<any>(null);
  _currentUser$ = this.currentUserSubject.asObservable();
  // currentLanguageName: string = this.appStorageService.getLanguage();

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: AppMessageService,
    private appStorageService: AppStorageService,
    private afAuth: AngularFireAuth,
    private afAuthGuard: AngularFireAuthGuard
  ) {

    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        // this.isLoggedIn = true;
        this.appStorageService.setUserName(user.email);
        this.currentUserSubject.next(user.email);
      } else {
        // this.isLoggedIn = false;
        this.appStorageService.setUserName('');
        this.currentUserSubject.next('');
      }
    });

  }


  authenticate(credentials) {
    // const httpOptions = ;

    return this.http.post<any>(baseLoginUrl, {}, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json;charset=UTF-8',
        Credentials: 'Basic ' + btoa(credentials.username + ':' + credentials.password)
      }),
      // params: {username: credentials.username, password: credentials.password},
      observe: 'response'
    })
      .pipe(map(response => {

        if (response) {
          // this.setToken(response);
          this.authenticated = true;
          this.appStorageService.setDataString('username', credentials.username);
        } else {
          this.authenticated = false;
        }

        // if (this.authenticated) {
        //   // this.redirectUrl = 'items';
        // }
        return this.authenticated;
      }));

    // return this.authenticated;
  }

  loginWithFire(credentials) {
    return from(this.afAuth.signInWithEmailAndPassword(credentials.username, credentials.password));
  }

  registrationFire(email: string, username: string, password: string) {
    const userCredentialPromise = this.afAuth.createUserWithEmailAndPassword(email, password);
    return from(userCredentialPromise);
  }

  firebaseGetTokenResult(userCredentials: UserCredential): Observable<any> {
    const idTokenResult = userCredentials.user.getIdTokenResult();
    return from(idTokenResult);
  }

  firebaseGetToken(userCredentials: UserCredential): Observable<any> {
    const idTokenResult = userCredentials.user.getIdToken();
    return from(idTokenResult);
  }

  resetPasswordWithFire(email: string): Observable<any> {
    // this.afAuth.languageCode = new Promise(() => this.currentLanguageName);
    return from(this.afAuth.sendPasswordResetEmail(email));
  }

  // verifyFireToken(idToken: string) {
  //   let params = new HttpParams();
  //   params = params.append('idToken', idToken);
  //
  //   // return this.http.get<boolean>(registerUrl + '/confirm', {params: params});
  //
  //   this.http.put<string>(baseTokeUrl, {}, {params: params})
  //     .subscribe(res => {
  //       console.log('12345==========================', res);
  //   });
  // // .subscribe(response => {
  // //     this.messageService.info('Page successfully updated.');
  // //     console.log('response log update page---', response);
  // //   });
  //   // this.afAuth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
  //   //   // Send token to your backend via HTTPS
  //   //   // ...
  //   //
  //   //   // this.http.post<any>(baseLoginUrl, idToken);
  //   // }).catch(function(error) {
  //   //   // Handle error
  //   // });
  //
  //   // this.afAuth.user.pipe(switchMap(user => user.getIdTokenResult()))
  //   //   .subscribe(idTokenResult => {
  //   //     console.log(idTokenResult.claims);
  //   //   });
  //
  //
  //
  //   // this.afAuth.currentUser.getIdToken(true)
  //   //   .then((token) => localStorage.setItem('tokenId', token));
  //   //


    // const userCredentialPromise = (response => {
    //   console.log('12345', response, response.user);
    // });
    // return from(userCredentialPromise);
  // }


  // getFireToken() {
  //   const token = this.appStorageService.getDataString('fireToken');
  //   console.log('==============================GET_TOKEN', token);
  //   return this.appStorageService.getDataString('fireToken');
  // }

  // setFireToken(token) {
  //   // const expiresAt = moment().add(authResult.expiresIn,'second');
  //   // const token = authResult.headers.get('Authorization');
  //   console.log('==============================SET_TOKEN', token);
  //   this.appStorageService.setDataString('fireToken', token ? token : '');
  //
  //   this.setLoggedUserRoles(token);
  // }
  // setFireTokenWithAuthorities(token) {
  //   this.appStorageService.setDataString('fireToken', token ? token.token : '');
  //   console.log('==============================SET_TOKEN', token.claims.authorities);
  //
  //   this.appStorageService.setDataString('authorities', token.claims.authorities);
  //   this.appStorageService.setDataString('userRole', 'ROLE_USER');
  //   this.appStorageService.setData('userPrivileges', ['READ_PRIVILEGE']);
  //
  // }

   getToken() {
    return this.appStorageService.getDataString('token');
  }

  private setToken(authResult) {
    // const expiresAt = moment().add(authResult.expiresIn,'second');
    const token = authResult.headers.get('Authorization');
    this.appStorageService.setDataString('token', token ? token : '');

    this.setLoggedUserRoles(token);
  }

  private setLoggedUserRoles(token) {
    const decodedToken = jwtDecode<JwtPayload>(token);

    const roles = (decodedToken as any).role;
    const userRole = roles.find(rol => rol.includes('ROLE_'));
    const userPrivileges = roles.filter(rol => rol.includes('_PRIVILEGE'));

    this.appStorageService.setDataString('userRole', userRole);
    this.appStorageService.setData('userPrivileges', userPrivileges);
  }

  getLoggedUserAuthorities(): string[] {
    return this.appStorageService.getUserAuthorities();
  }
  setLoggedUserAuthorities(authorities: string[]) {
    return this.appStorageService.setUserAuthorities(authorities);
  }

  getLoggedUserRole() {
    return this.appStorageService.getUserRole();
  }

  getUserName() {
    return this.appStorageService.getUserName();
  }

  logout() {
    this.afAuth.signOut();
    this.appStorageService.clearUserData();
    this.appStorageService.clearData();

    this.authenticated = false;

    this.appStorageService.clearCookie();
    this.router.navigate(['/login']);
  }

  isAdmin() {
    const loggedUserRole = this.getLoggedUserAuthorities();
    return loggedUserRole.includes(RoleType.ADMIN) || loggedUserRole.includes(RoleType.SUPER_ADMIN);
  }

  isVendor() {
    const loggedUserRole = this.getLoggedUserAuthorities();
    return loggedUserRole.includes(RoleType.VENDOR);
  }

  isReviewer() {
    const loggedUserRole = this.getLoggedUserAuthorities();
    return loggedUserRole.includes(RoleType.REVIEWER);
  }

  isAdminOrVendor() {
    return this.isAdmin() || this.isVendor() || this.isReviewer();
  }

  isAuthenticated() {
    // return this.isAuthenticatedUserName;
    if (!this.getUserName() || !this.getLoggedUserAuthorities()) {
      return false;
    }
    return true;
  }

  get isAuthenticatedUser(): boolean {
    return this._user$ !== null;
  }

  get isAuthenticatedUserName() {
    const user = this.afAuth.currentUser;

    return this._currentUser$;
  }

  get isNewUser(): boolean {
    return this.appStorageService.getData('isNewUser');
  }

  setNewUser(isNewUser: boolean) {
    this.appStorageService.setData('isNewUser', isNewUser);
  }

  handleResetPassword(actionCode, continueUrl, lang, newPassword) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    this.afAuth.verifyPasswordResetCode(actionCode).then((email) => {
      const accountEmail = email;

      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.
      // const newPassword = newPassword;

      // Save the new password.
      this.afAuth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);


        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        this.messageService.info('Password successfully recovered');
        this.router.navigate([this.redirectUrl]);
      }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    });

  }

  logoutApplication(): Observable<string>  {
    return this.http.put<string>(baseLogoutUrl, {});
  }

  userLogoutApplication(): Observable<string>  {
    return this.http.put<string>(userLogoutUrl, {});
  }

}
