import {Message} from './Message';
import {MessageType} from './MessageType';
import {Injectable} from '@angular/core';
import {AlignType} from './AlignType';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AppMessageComponent} from './app-message.component';
import {ActionType} from './ActionType';

@Injectable()
export class AppMessageService {

  constructor(private _snackBar: MatSnackBar) {}


  openSnackBar(message: Message, action: string,
               verticalPosition: MatSnackBarVerticalPosition, horizontalPosition: MatSnackBarHorizontalPosition) {
    const snackBarAction = this._snackBar.openFromComponent(AppMessageComponent, {
      data: {
        message: message.body,
        action: action
      },
      duration: 5 * 1000,
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition,
      panelClass: this.notificationCssClass(message)
    });
  }

  // convenience methods
  basic(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.BASIC}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  white(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.WHITE}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  success(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.SUCCESS}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  error(message: string) {
    // this.message(new Message({body: message, type: MessageType.INFO, id}));
    // showNotification(FormType.TOP, AlignType.RIGHT, MessageType.INFO, message, '');
    this.openSnackBar(new Message({body: message, type: MessageType.ERROR}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  danger(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.DANGER}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  info(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.INFO}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  accent(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.ACCENT}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  warn(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.WARN}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  primary(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.PRIMARY}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  secondary(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.SECONDARY}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  light(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.LIGHT}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }

  dark(message: string) {
    this.openSnackBar(new Message({body: message, type: MessageType.DARK}), ActionType.CLOSE, AlignType.BOTTOM, AlignType.CENTER);
  }


  notificationCssClass(message: Message) {
    if (!message) {
      return;
    }
    // return css class based on message type
    switch (message.type) {
      case MessageType.BASIC:
        return 'basic-snackbar';
      case MessageType.WHITE:
        return 'white-snackbar';
      case MessageType.SUCCESS:
        return 'success-snackbar';
      case MessageType.ERROR:
        return 'error-snackbar';
      case MessageType.INFO:
        return 'info-snackbar';
      case MessageType.PRIMARY:
        return 'primary-snackbar';
      case MessageType.ACCENT:
        return 'accent-snackbar';
      case MessageType.WARN:
        return 'warn-snackbar';
      case MessageType.LINK:
        return 'link-snackbar';
      case MessageType.WARNING:
        return 'warning';
      case MessageType.DANGER:
        return 'danger';
      case MessageType.SECONDARY:
        return 'secondary';
      case MessageType.LIGHT:
        return 'light';
      case MessageType.DARK:
        return 'dark';
      default: {
        return '';
      }
    }
  }
}
