import {AfterViewInit, Component} from '@angular/core';
import {AppMessageService} from '../../../message/app-message.service';
import {RegistrationService} from '../../registration/registration.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../auth/authentication.service';
import {AppStorageService} from '../../../storageService/app-storage.service';

const emailValidator = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'app-reset-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

  forgotPasswordEmail: string;
  emailValidatorPattern: string;
  currentLanguage = this.appStorageService.getLanguage();
  currentDomain = this.appStorageService.getDomainString();
  // currentLanguageLabel = getLanguageKeyByValue(this.appLocaleService.getCurrentLanguage());

  constructor(private router: Router,
              private registrationService: RegistrationService,
              private messageService: AppMessageService,
              private appStorageService: AppStorageService,
              private authenticationService: AuthenticationService) {


    this.emailValidatorPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  }

  onSubmitVerificationCode() {
    this.registrationService.forgotPassword(this.currentDomain, this.currentLanguage, this.forgotPasswordEmail,
      this.getMessageSubject('sendCode'), this.constructMessageTextBodyForEmail())
      .subscribe({
        next: send => {
          // this.emailVerified = true;
          this.messageService.info('Verification code was send to your email address');
          // if (this.credentials) {
          //   this.login();
          // } else {
          //   this.router.navigate(['/login']);
          // }
        }
      });
    // this.login();
  }

  getMessageSubject(type: string) {
    if (type === 'sendCode') {
      return $localize`Verify your email for Monochrome.bg`;
    }
    return '';
  }

  getMessageBody(type: string) {
    return $localize `Please enter a valid email`;
  }

  constructMessageTextBodyForEmail() {
    const divEl = document.createElement('div');
    const pHello = document.createElement('p');
    const textHello = document.createTextNode($localize`Hello,`);
    // const appendSpan = document.createElement('span');
    const textAppendEmail = document.createTextNode(' replace with email');
    pHello.appendChild(textHello);
    pHello.appendChild(textAppendEmail);

    const pFollow = document.createElement('p');
    const textFollow = document.createTextNode($localize`Follow this link to reset your password.`);
    pFollow.appendChild(textFollow);

    const pLink = document.createElement('p');
    const textLink = document.createTextNode('replace with link');
    pLink.appendChild(textLink);

    const pIf = document.createElement('p');
    const textIf = document.createTextNode($localize`If you didn’t ask to reset your password, you can ignore this email.`);
    pIf.appendChild(textIf);

    const pThanks = document.createElement('p');
    const textThanks = document.createTextNode($localize`Thanks,`);
    pThanks.appendChild(textThanks);

    const pTeam = document.createElement('p');
    const textTeam = document.createTextNode($localize`Your Monochrome.bg team.`);
    pTeam.appendChild(textTeam);

    divEl.appendChild(pHello);
    divEl.appendChild(pFollow);
    divEl.appendChild(pLink);
    divEl.appendChild(pIf);
    divEl.appendChild(pThanks);
    divEl.appendChild(pTeam);

    // const currentDiv = document.getElementById('forgotPasswordEmailMessageId');
    // currentDiv.appendChild(divEl);

    return divEl.outerHTML;
  }
// const auth = this.authenticationService.isAuthenticated();

  // .catch((error) => {
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  //   // ..
  // });

  // sendPasswordResetEmail() {
    // this.authenticationService.resetPasswordWithFire(this.forgotPasswordEmail)
    //   .subscribe(() => {
    //     this.messageService.info('Verified code was send to your email address');
    //     this.onSubmitVerificationCode();
    //     // Password reset email sent!
    //     // ..
    //   });
  // }

}
