import {Component, Inject, LOCALE_ID} from '@angular/core';
import {User} from '../User';
import {UserService} from '../user.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppMessageService} from '../../message/app-message.service';
import {Router} from '@angular/router';
import {RoleType} from '../RoleType';
import {RoleDto} from '../RoleDto';
import {AppTermAndConditionService} from '../../app-term-and-condition/app-term-and-condition.service';
import {Subscription} from 'rxjs';
import {TermAndCondition} from '../../admin/admin-term-and-condition/TermAndCondition';
import {TermAndConditionDialog} from '../../app-term-and-condition/term-and-condition-dialog/term-and-condition-dialog';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../auth/authentication.service';
import {getLocaleId} from '@angular/common';
import {AppStorageService} from '../../storageService/app-storage.service';

@Component({
  selector: 'app-login-sell',
  templateUrl: './register-sell.component.html',
  styleUrl: './register-sell.component.scss'
})
export class RegisterSellComponent {

  registerSellForm: FormGroup;
  user: User = new User();
  roleType = RoleType;
  selectedRole: RoleType = RoleType.VENDOR;
  roles: RoleDto[] = [];
  showPassword = false;
  showPasswordConfirm = false;
  currentLanguage = getLocaleId(this.locale);
  registerSellCurrentDomain = this.storageService.getDomainString();

  termAndCondition: TermAndCondition;
  termsAndConditionSubscription: Subscription;

  constructor(private router: Router,
              private fb: FormBuilder,
              private userService: UserService,
              private messageService: AppMessageService,
              private termAndConditionService: AppTermAndConditionService,
              private termAndConditionDialog: MatDialog,
              private authenticationService: AuthenticationService,
              private storageService: AppStorageService,
              @Inject(LOCALE_ID) public locale: string) {


    this.registerSellForm = this.fb.group({
        firstName: [''],
        lastName: [''],
        username: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: [''],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        // companyName: ['', Validators.required],
        // phoneNumber: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15),
        //   Validators.pattern('[- +()0-9]{5,15}')]],
        role: [this.selectedRole, Validators.required],
        eik: ['', [Validators.minLength(9), Validators.maxLength(9)]]
      },
      // {
      //   // validators: this.password.bind(this)
      //   validators: MustMatch('password', 'confirmPassword')
      // }
    );
  }

  createMarketplaceRegistration() {
    const email1 = this.registerSellForm.get('email').value;
    this.registerSellForm.patchValue({
      username: email1,
      // formControlName2: myValue2 (can be omitted)
    });
    // this.registrationForm.get('role').value;
    // let userr = this.registrationForm.value;

    const regUser = this.registerSellForm.value;
    // this.roles.push({id: null, name: this.selectedRole, description: null, isActive: true});
    // regUser.roles = this.roles;
    regUser.role = this.selectedRole;
    regUser.roles = [];
    regUser.roles.push({
      id: null,
      name: this.selectedRole,
      description: '',
      active: true,
      permissions: []});

    this.authenticationService.registrationFire(regUser.username, regUser.username, regUser.password)
      .subscribe(responseFire => {

        if (responseFire) {
          regUser.uid = responseFire.user.uid;
          // regUser.isNewUser = responseFire.additionalUserInfo.isNewUser;
          // regUser.uid = responseFire.additionalUserInfo.providerId; = password
          this.authenticationService.setNewUser(responseFire.additionalUserInfo.isNewUser);

          this.userService.registerUserVendor(this.registerSellCurrentDomain, regUser)
            .subscribe({
              next: response => {
                this.authenticationService.setNewUser(false);
                this.messageService.info('Your account is successfully created!');

                // const credentials = {username: this.email.value, password: this.password.value};
                // this.router.navigate(['/notification/confirm'], {state: {credentials: credentials}});

                // this.login();
                this.router.navigate(['/login']);
              },
              error: error => {
                // this.errorMessage = error.message;
                // for (const [key, value] of Object.entries(error)) {
                //   // error = (`${key}: ${value}`) || error.statusText;
                //   error = (`${value}`) || error.statusText;
                //   this.messageService.error(error);
                //   console.error('There was an error!', error);
                // }
                this.messageService.danger(error);
              }
            });
        }
      });
  }

  onSubmitRegisterSellForm() {
    if (this.registerSellForm.invalid) {
      return;
    }
    this.createMarketplaceRegistration();
  }

  login() {
    const credentials = {username: this.email.value, password: this.password.value};

    this.authenticationService.authenticate(credentials)
      .subscribe({
        next: data => {
          if (this.authenticationService.authenticated) {
            this.messageService.info('You have successfully registered and logged in');
            let redirect = '/user-profile';

            const loggedUserRole = this.storageService.getUserRole();
            if (loggedUserRole === RoleType.ADMIN || loggedUserRole === RoleType.VENDOR) {
              redirect = '/admin';
            }
            this.router.navigate([redirect]);
          }
        },
        error: error => {
          // this.errorMessage = error.message;
          this.messageService.warn('Please check your username/password and try again please');
          console.error('There was an error!', error);
        }

      });
  }

  getErrorMessage(field: string) {
    if (field === 'email' && this.email.hasError('email')) {
      return $localize`Please enter a valid email`;
    }
    if (field === 'password' && this.password.hasError('minlength')) {
      return $localize`Password must be 6 characters or more.`;
    }
    // Единен идентификационен код
    // Персонален идентификационен код
    if (field === 'eik' && this.eik.hasError('required')) {
      return $localize`Please enter a EIK/PIK number`;
    }
    // if (field === 'companyName' && this.companyName.hasError('required')) {
    //   return $localize`Please enter a company name`;
    // }
    // if (field === 'phone' && this.phone.hasError('required')) {
    //   return $localize`Please enter a phone number`;
    // }

    return '';
  }

  // not user - just another approach for MustMatch.ts
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return {invalid: true};
    }
  }

  showTermsAndConditions() {
    this.openTermAndConditionDialog(this.termAndCondition);
  }

  openTermAndConditionDialog(term: TermAndCondition): void {
    const dialogRef = this.termAndConditionDialog.open(TermAndConditionDialog, {
      width: '750px',
      data: {termAndCondition: term}
    });

    dialogRef.afterClosed().subscribe(termAndCond => {
      if (termAndCond) {

      }
    });
  }

  readTermsAndConditions() {
    this.termsAndConditionSubscription = this.termAndConditionService.
    findAllTermAndConditionByDomainAndLanguage(this.registerSellCurrentDomain, this.selectedRole, this.currentLanguage)
      .subscribe({
        next: terms => {
          if (terms) {
            this.termAndCondition = terms;
            this.showTermsAndConditions();
          }
        },
        error: error => {
          console.error(error);
        }
      });
  }

  get firstName(): any {
    return this.registerSellForm.get('firstName');
  }
  get lastName(): any {
    return this.registerSellForm.get('lastName');
  }
  get username(): any {
    return this.registerSellForm.get('username');
  }
  get email(): any {
    return this.registerSellForm.get('email');
  }
  get password(): any {
    return this.registerSellForm.get('password');
  }
  get confirmPassword(): any {
    return this.registerSellForm.get('confirmPassword');
  }
  get eik(): any {
    return this.registerSellForm.get('eik');
  }
  get companyName(): any {
    return this.registerSellForm.get('companyName');
  }
  get phoneNumber(): any {
    return this.registerSellForm.get('phoneNumber');
  }

  toLowerCaseEmail() {
    if (this.email.value) {
      this.email.setValue(this.email.value.toLowerCase());
    }
  }

}
