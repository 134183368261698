<!--<div style="background-image: url('assets/images/natrure-1.jpg'); height: 100vh;" class="bg-image">-->
<div class="section-image">

  <div class="container-fluid-login">
    <div class="card shadow ">
      <div class="card-header mt-n3 mx-3 bg-indigo ">
        <h4 i18n class="card-title text-center text-white">Forgot Password</h4>
        <p i18n class="card-category text-center text-white">We wll send a verification code.</p>
      </div>
      <div class="card-body mx-3">
        <div class="text-center mt-2 mb-3">
          <button mat-button routerLink="/home" color="primary" class="fw-bold fs-4 text-primary-logo">
            <span i18n>Monochrome</span>
          </button>
        </div>

        <div class="row mt-4 mb-1">
          <div class="col">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label class=" ">
                <mat-icon *ngIf="this.forgotEmail.invalid" class="material-symbols-outlined">drafts</mat-icon>
                <mat-icon *ngIf="this.forgotEmail.valid" class="material-symbols-outlined">mail_outline</mat-icon>
                <span i18n class="ms-1">Email</span>
              </mat-label>
              <input type="email" matInput #forgotEmail="ngModel"
                     required
                     [pattern]="emailValidatorPattern"
                     [class.is-invalid]="forgotEmail.invalid && forgotEmail.touched" required
                     [(ngModel)]="forgotPasswordEmail"/>
              <mat-error i18n *ngIf="forgotEmail.invalid && forgotEmail.errors.required">
                Email is required
              </mat-error>
              <mat-error i18n *ngIf="forgotEmail.invalid && forgotEmail.errors.pattern">
                Please provide a valid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="mt-2 text-center">
          <!--          New to Monochrome?-->
          <button i18n mat-raised-button color="primary" class="w-100" type="button"
                  [disabled]="forgotEmail.invalid"
                  (click)="onSubmitVerificationCode()">
            Send code
          </button>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button i18n  mat-button routerLink="/login" color="primary" class="">
            Sign in
          </button>
        </div>
        <div class="pt-2 text-center">
          <button i18n mat-button routerLink="/register" color="primary" class="">
            Join now
          </button>
        </div>

        <div class="row">
          <div class="text-center mt-2">
            <app-language-select [buttonType]="'button'"></app-language-select>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!--<div id="forgotPasswordEmailMessageId"></div>-->

<!--</div>-->
