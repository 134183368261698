import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './user/login/login.component';
import {RegistrationComponent} from './user/registration/registration.component';
import {PageNotFoundComponent} from './common/page_not_found/page-not-found.component';
import {AuthGuard} from './auth/auth-guard.service';
import {SelectivePreloadingStrategyService} from './selective-preloading-strategy.service';
import {RoleType} from './user/RoleType';
import {RoleGuardService} from './auth/role-guard.service';
import {ForgotPasswordComponent} from './user/credentials/forgot-password/forgot-password.component';
import {RegisterSellComponent} from './user/login-sell/register-sell.component';
import {ResetPasswordComponent} from './user/credentials/reset-password/reset-password.component';

const appRoutes: Routes = [
  {
    path: 'marketplace',
    component: RegisterSellComponent
  },
  {
    path: 'register',
    component: RegistrationComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, RoleGuardService],
    data: {roles: [RoleType.SUPER_ADMIN, RoleType.ADMIN, RoleType.REVIEWER, RoleType.VENDOR]}
    // data: {expectedRole: ROLE.ADMIN}},
  },
  {
    path: '', // have to be main module!?
    loadChildren: () => import('./_layout/app-layout.module').then(m => m.AppLayoutModule),
    // canActivate: [AuthGuard]  // some pages are not under auth guard - canActivateChild
    // data: { preload: true }
  },
  {
    path: '**',
    // redirectTo: '/login'
    component: PageNotFoundComponent
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // {
      //   enableTracing: true,
      //   preloadingStrategy: SelectivePreloadingStrategyService
      // }
      // Call enableProdMode() to enable the production mode. // - enable production but where to invoke this method!!!
    )
    // RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

