export enum ErrorMessage {
  // ERROR_MESSAGE_USER_EXISTS = 'Username already exists: ',
  // ERROR_MESSAGE_PASSWORD_NOT_MATCH = 'Passwords do not match',
  // ERROR_MESSAGE_SAME_OLD_AND_NEW_PASSWORD = 'Fields: oldPassword and newPassword do not match',
  // ERROR_MESSAGE_SAME_NEW_AND_CONFIRM_PASSWORD = 'Fields: newPassword and confirmPassword do not match',
  // ERROR_MESSAGE_CREDENTIALS_NOT_VALID = 'Credentials are not valid',
  // ERROR_MESSAGE_WRONG_PARAMETERS = 'Wrong parameters, please try again: ',
  // ERROR_MESSAGE_ROLE_WRONG = 'Role is not correct: ',
  ERROR_MESSAGE_USER_EXISTS,
  ERROR_MESSAGE_PASSWORD_NOT_MATCH,
  ERROR_MESSAGE_SAME_OLD_AND_NEW_PASSWORD,
  ERROR_MESSAGE_SAME_NEW_AND_CONFIRM_PASSWORD,
  ERROR_MESSAGE_CREDENTIALS_NOT_VALID,
  ERROR_MESSAGE_WRONG_PARAMETERS,
  ERROR_MESSAGE_ROLE_WRONG,
  ERROR_MESSAGE_CONFIRMATION_EMAIL,
  ERROR_MESSAGE_STORE_EXIST,
  ERROR_MESSAGE_STORE_NOT_ENABLED,

  ERROR_MESSAGE_TOO_MANY_LOGIN_ATTEMPTS,
  ERROR_MESSAGE_BAD_CREDENTIALS,

  RESET_PASSWORD_INVALID_LINK,
  RESET_PASSWORD_LINK_WAS_USED,
  RESET_PASSWORD_LINK_EXPIRED,
  ERROR_MESSAGE_STORE_EIK_PIK_EXIST,

}
// const Fruits = {};
// Object.freeze(Fruits);

export function convertToErrorEnum(key): ErrorMessage | undefined {
  return ErrorMessage[key as keyof typeof ErrorMessage];
}

/**
 * This is equivalent to:
 * type LogLevelStrings = 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';
 */
type ErrorMessageStrings = keyof typeof ErrorMessage;

export function printImportant(key: ErrorMessageStrings, message: string) {
  const num = ErrorMessage[key];
  // if (num <= ErrorMessage.ERROR) {
    console.log('Log level key is:', key);
    console.log('Log level value is:', num);
    console.log('Log level message is:', message);
    return key;
  // }
}
// printImportant('ERROR', 'This is a message');


export function getErrorMessage(message: ErrorMessage): string {
  switch (message) {
    case ErrorMessage.ERROR_MESSAGE_USER_EXISTS:
      return $localize`Username already exists: `;
    case ErrorMessage.ERROR_MESSAGE_PASSWORD_NOT_MATCH:
      return $localize`Passwords do not match`;
    case ErrorMessage.ERROR_MESSAGE_SAME_OLD_AND_NEW_PASSWORD:
      return $localize`Fields: oldPassword and newPassword can not match`;
    case ErrorMessage.ERROR_MESSAGE_SAME_NEW_AND_CONFIRM_PASSWORD:
      return $localize`Fields: newPassword and confirmPassword do not match`;
    case ErrorMessage.ERROR_MESSAGE_CREDENTIALS_NOT_VALID:
      return $localize`Credentials are not valid`;
    case ErrorMessage.ERROR_MESSAGE_WRONG_PARAMETERS:
      return $localize`Wrong parameters, please try again: `;
    case ErrorMessage.ERROR_MESSAGE_ROLE_WRONG:
      return $localize`Role is not correct: `;

    case ErrorMessage.ERROR_MESSAGE_CONFIRMATION_EMAIL:
      return $localize`Confirmation email was not succeeded`;
    case ErrorMessage.ERROR_MESSAGE_STORE_EXIST:
      return $localize`Store already exists`;
    case ErrorMessage.ERROR_MESSAGE_STORE_NOT_ENABLED:
      return $localize`Store not enabled`;

    case ErrorMessage.ERROR_MESSAGE_TOO_MANY_LOGIN_ATTEMPTS:
      return $localize`Your account has been locked due to 5 failed login attempts. Please try again after 5 minutes`;
    case ErrorMessage.ERROR_MESSAGE_BAD_CREDENTIALS:
      return $localize`We're sorry, but the credentials you entered are incorrect. Please double-check your username and password and try again.`;

    case ErrorMessage.RESET_PASSWORD_INVALID_LINK:
      return $localize`We're sorry, but the credentials you entered are incorrect. Please double-check your username and password and try again.`;
    case ErrorMessage.RESET_PASSWORD_LINK_WAS_USED:
      return $localize`We're sorry, but the link was already used.`;
    case ErrorMessage.RESET_PASSWORD_LINK_EXPIRED:
      return $localize`We're sorry, but the link is expired`;
    case ErrorMessage.ERROR_MESSAGE_STORE_EIK_PIK_EXIST:
      return $localize`Store or eikPik number already exists`;

    default:
      return $localize`Unknown Error`;
  }
}

